import socialMediaLinks from '@/data/social-media-links';
import SocialMediaLink from '@/components/common/social-media-link';
import Link from 'next/link';
import {getYear} from 'date-fns';
import {FiMoon, FiSun} from 'react-icons/fi';
import {Component} from 'react';
import {FormattedMessage} from 'react-intl';

interface FooterState {
  readonly darkModeEnabled: boolean;
}

class Footer extends Component<{}, FooterState> {
  public readonly state: FooterState;

  constructor(props: {}) {
    super(props);
    this.state = {darkModeEnabled: this.isDarkModeEnabled()};
  }

  public componentDidMount() {
    // this.applyDarkMode(this.state.darkModeEnabled);
  }

  public render(): JSX.Element {
    const {darkModeEnabled} = this.state;
    return (
      <footer className="text-sm bg-accent-500 dark:bg-gray-800 text-white pt-6">
        <div className="container mx-auto flex flex-wrap">
          <div className="w-full py-6">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/3 py-6">
                <div className="flex items-center">
                  <img src="assets/images/soubul-192-white.png" alt="Soubul" className="block w-14" />
                  <div className="mx-3 flex flex-col">
                    <span className="text-lg font-light tracking-widest">
                      <FormattedMessage id="words.soubul" />
                    </span>
                    <span className="text-xs -mt-1 tracking-widest">
                      <FormattedMessage id="words.academy" />
                    </span>
                  </div>
                </div>
                <p className="my-4">
                  <FormattedMessage id="content.about" />
                </p>
              </div>
              <div className="w-full lg:w-1/3 py-6" />
              <div className="w-full lg:w-1/3 py-6">
                <p className="text-base mb-2 uppercase">
                  <FormattedMessage id="words.company" />
                </p>
                <p className="mb-2">
                  <Link href="/about" passHref>
                    <a className="inline-block text-light-200 hover:text-white">
                      <FormattedMessage id="words.about_us" />
                    </a>
                  </Link>
                </p>
                {/* <p className="mb-2">
                  <Link href="/support/security" passHref>
                    <a className="inline-block text-light-200 hover:text-white">
                      <FormattedMessage id="components.footer.security_link" />
                    </a>
                  </Link>
                </p> */}
                <p className="mb-2">
                  <Link href="/support/terms-of-service" passHref>
                    <a className="inline-block text-light-200 hover:text-white">
                      <FormattedMessage id="words.terms_of_service" />
                    </a>
                  </Link>
                </p>
                <p className="mb-2">
                  <Link href="/support/privacy-policy" passHref>
                    <a className="inline-block text-light-200 hover:text-white">
                      <FormattedMessage id="words.privacy_policy" />
                    </a>
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full py-4 border-t border-b border-light-400">
            <div className="flex flex-wrap items-center justify-center lg:justify-between">
              <div className="flex items-center justify-center my-4 md:my-0">
                <p className="text-lg ltr:mr-2 rtl:ml-2">
                  <FormattedMessage id="words.follow_us" />
                </p>
                {socialMediaLinks.map(({key, ...rest}) => (
                  <SocialMediaLink key={key} {...rest} />
                ))}
              </div>
              <div className="flex items-center my-4 md:my-0">
                <button className="hidden" onClick={this.toggleDarkMode}>
                  {darkModeEnabled ? <FiSun /> : <FiMoon />}
                  <span className="mx-2">{darkModeEnabled ? 'Light Mode' : 'Dark Mode'}</span>
                </button>
              </div>
            </div>
          </div>
          <div className="w-full text-center py-6">
            <span>&copy;&nbsp;{getYear(Date.now())}&nbsp;</span>
            <FormattedMessage id="words.soubul" />
            <span>.&nbsp;</span>
            <FormattedMessage id="components.footer.copyrights" />
            <span>&nbsp;</span>
            <FormattedMessage id="components.footer.powered_by_tabtop" />
          </div>
        </div>
      </footer>
    );
  }

  /** @internal */
  private toggleDarkMode = (): void => {
    const darkModeEnabled = !this.state.darkModeEnabled;
    this.setState({darkModeEnabled});
    this.applyDarkMode(darkModeEnabled);
    this.storeDarkModeState(darkModeEnabled);
  };

  /** @internal */
  private isDarkModeEnabled = () => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem('DarkMode') === '1';
    }
    return false;
  };

  /** @internal */
  private applyDarkMode = (enabled: boolean) => {
    if (typeof document !== 'undefined') {
      if (enabled) {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
    }
  };

  /** @internal */
  private storeDarkModeState = (enabled: boolean) => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('DarkMode', (enabled ? 1 : 0).toString());
    }
  };
}

export default Footer;
