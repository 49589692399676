import DropDown from '@/components/dropdown/dropdown';
import DropDownToggle from '@/components/dropdown/dropdown-toggle';
import DropDownMenu from '@/components/dropdown/dropdown-menu';
import DropDownItem from '@/components/dropdown/dropdown-item';
import NextLink from '@/components/next-link';
import axios from '@/utils/axios';
import cx from 'classnames';
import type {WithAuthProps} from '@/contexts/auth-context';
import {withAuth} from '@/contexts/auth-context';
import {FormattedMessage} from 'react-intl';
import {FiCpu, FiLogOut, FiRefreshCcw, FiSettings, FiUser} from 'react-icons/fi';
import {Component} from 'react';
import type {WithAlertsProps} from '@/contexts/alerts-context';
import {withAlerts} from '@/contexts/alerts-context';
import {withRouter} from 'next/router';
import {WithRouterProps} from 'next/dist/client/with-router';
import {User, UserRole} from '@soubul/ts-commons';

interface Props extends WithAuthProps, WithAlertsProps, WithRouterProps {
  readonly displayUserName?: boolean;
}

class UserDropDownMenu extends Component<Props> {
  public render(): JSX.Element | null {
    const {displayUserName, session} = this.props;
    const {user, signOut} = session;
    return (
      user && (
        <DropDown>
          <DropDownToggle className="w-full">
            <img src={user.avatarUrl} alt={user.email} className="block w-10 rounded-full border-2 border-primary-50" />
            <p className={cx('mx-3 text-sm', {['lg:hidden']: !displayUserName})}>{user.name}</p>
          </DropDownToggle>

          <DropDownMenu>
            <DropDownItem role="button">
              <p className="whitespace-nowrap px-3">
                <span className="block">{user.name}</span>
                <span className="text-gray-500 text-sm">
                  <FormattedMessage id={user.role} />
                </span>
              </p>
            </DropDownItem>

            {user.role === UserRole.Provider && (
              <DropDownItem icon={<FiRefreshCcw />} onClick={this.switchAccount(UserRole.User)}>
                <FormattedMessage id="components.user_drop_down_menu.switch_to_user_account" />
              </DropDownItem>
            )}

            {user.hasProviderAccount && user.role !== UserRole.Provider && (
              <DropDownItem icon={<FiRefreshCcw />} onClick={this.switchAccount(UserRole.Provider)}>
                <FormattedMessage id="components.user_drop_down_menu.switch_to_provider_account" />
              </DropDownItem>
            )}

            {user.role === UserRole.Admin && (
              <NextLink href="/admin" passHref>
                <DropDownItem icon={<FiCpu />}>
                  <FormattedMessage id="words.dashboard" />
                </DropDownItem>
              </NextLink>
            )}

            <NextLink href={user.role === UserRole.User ? '/user/profile' : `/provider/${user.username}`} passHref>
              <DropDownItem icon={<FiUser />}>
                <FormattedMessage id="words.profile" />
              </DropDownItem>
            </NextLink>

            <NextLink href={user.role === UserRole.User ? '/user/settings' : '/provider/settings'} passHref>
              <DropDownItem icon={<FiSettings />}>
                <FormattedMessage id="words.settings" />
              </DropDownItem>
            </NextLink>

            <DropDownItem role="button" icon={<FiLogOut />} onClick={signOut}>
              <FormattedMessage id="words.sign_out" />
            </DropDownItem>
          </DropDownMenu>
        </DropDown>
      )
    );
  }

  private switchAccount = (role: UserRole) => async () => {
    try {
      const res = await axios.post('/users/switch-account', {role});
      if (res.data) {
        this.props.session.setUser(User.fromJSON(res.data));
        // await this.props.router.push({ pathname: "/" });
        this.props.alerts.success(`Switched to ${role} account successfully.`);
      }
    } catch (e) {
      this.props.alerts.error('Unable to switch account, please try again.');
    }
  };
}

export default withRouter(withAlerts(withAuth(UserDropDownMenu)));
