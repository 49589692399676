import Link from 'next/link';
import {cloneElement, isValidElement} from 'react';
import type {LinkProps} from 'next/link';
import type {FC, ReactNode} from 'react';

interface NextLinkProps extends LinkProps {
  readonly children: ReactNode;
}

const NextLink: FC<NextLinkProps> = ({
  // LinkProps
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  // Other props
  children,
  ...props
}) => (
  <Link
    href={href}
    as={as}
    replace={replace}
    scroll={scroll}
    shallow={shallow}
    passHref={passHref}
    prefetch={prefetch}
    locale={locale}
  >
    {isValidElement(children) ? cloneElement(children, {...props}) : children}
  </Link>
);

export default NextLink;
