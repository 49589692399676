import type {IconType} from 'react-icons';
import type {FC} from 'react';

export interface SocialMediaLinkProps {
  readonly title: string;
  readonly href: string;
  readonly icon: IconType;
}

const SocialMediaLink: FC<SocialMediaLinkProps> = ({title, href, icon: Icon}) => (
  <a
    className="mx-2 opacity-50 transition-opacity duration-200 relative inline-block overflow-hidden dark:opacity-50 hover:opacity-100 hover:overflow-visible bg-lighter rounded-full p-2"
    href={href}
    target="_blank"
    rel="noreferrer"
  >
    <span className="absolute left-1/2 -top-full bg-dark-50 text-light-50 font-semibold rounded-md shadow-lg px-2 py-1 font-thin text-sm transform -translate-x-1/2 -translate-y-1/2 dark:bg-light-50 dark:text-dark-50">
      {title}
    </span>
    <Icon size={15} />
  </a>
);

export default SocialMediaLink;
