import styles from '@/components/dropdown/dropdown.module.scss';
import {FiChevronDown, FiChevronUp} from 'react-icons/fi';
import type {ComponentProps, FC} from 'react';
import cx from 'classnames';

interface Props extends ComponentProps<'button'> {
  readonly dropdown?: {
    readonly isOpen: boolean;
    readonly toggle: () => void;
  };
  readonly chevron?: boolean;
}

const DropDownToggle: FC<Props> = ({className, children, dropdown, chevron, ...props}) => (
  <button {...props} className={cx(styles.dropdown_toggle, className)} onClick={dropdown?.toggle}>
    <div className="mx-1 flex items-center justify-center">{children}</div>
    {chevron !== false && <div>{dropdown?.isOpen ? <FiChevronUp /> : <FiChevronDown />}</div>}
  </button>
);

export default DropDownToggle;
