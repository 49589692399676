import styles from '@/components/dropdown/dropdown.module.scss';
import cx from 'classnames';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import type {FC, MouseEvent, ReactNode} from 'react';

interface DropDownItemProps extends ComponentPropsWithoutRef<'a'> {
  readonly icon?: ReactNode;
  readonly dropdown?: {
    readonly isOpen: boolean;
    readonly toggle: () => void;
  };
}

const DropDownItem: FC<DropDownItemProps> = forwardRef<HTMLAnchorElement, DropDownItemProps>(
  ({className, icon, children, onClick, dropdown, ...props}, ref) => {
    const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
      onClick?.call(event.target, event);
      dropdown?.toggle();
    };
    return (
      <a {...props} className={cx(styles.dropdown_item, className)} onClick={handleClick} ref={ref}>
        {icon && <span className="w-6">{icon}</span>}
        <span className="flex-1 px-2 flex items-center justify-start min-w-max">{children}</span>
      </a>
    );
  },
);

export default DropDownItem;
