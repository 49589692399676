import cx from 'classnames';
import styles from '@/components/dropdown/dropdown.module.scss';
import {AnimatePresence, motion} from 'framer-motion';
import {Children, cloneElement, isValidElement} from 'react';
import {useRouter} from 'next/router';
import type {ReactNode, ReactNodeArray, FC} from 'react';

interface DropDownMenuProps {
  readonly dropdown?: {
    readonly isOpen: boolean;
    readonly toggle: () => void;
  };
  readonly children: ReactNode | ReactNodeArray;
  readonly placement?: 'top' | 'bottom';
}

const animation = {
  hidden: {
    opacity: 0,
    scale: 0,
    width: 0,
    height: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    width: 'auto',
    height: 'auto',
  },
};

const DropDownMenu: FC<DropDownMenuProps> = ({dropdown, placement, children}) => {
  const router = useRouter();
  return (
    <AnimatePresence>
      {dropdown?.isOpen && (
        <motion.div
          className={cx(styles.dropdown_menu, {
            [styles.dropdown_menu_top]: placement === 'top',
            [styles.dropdown_menu_ltr]: router.locale === 'en',
            [styles.dropdown_menu_rtl]: router.locale === 'ar',
          })}
          variants={animation}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          {Children.map(children, (child) => (isValidElement(child) ? cloneElement(child, {dropdown}) : child))}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DropDownMenu;
