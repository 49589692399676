import type {SocialMediaLinkProps} from '@/components/common/social-media-link';
import {SiFacebook, SiInstagram, SiLinkedin, SiTwitter} from 'react-icons/si';

const socialMediaLinks: Array<SocialMediaLinkProps & {key: string}> = [
  {
    key: 'twitter',
    title: 'Twitter',
    href: 'https://twitter.com/',
    icon: SiTwitter,
  },
  {
    key: 'instagram',
    title: 'Instagram',
    href: 'https://www.instagram.com/',
    icon: SiInstagram,
  },
  {
    key: 'linkedin',
    title: 'LinkedIn',
    href: 'https://www.linkedin.com/in/',
    icon: SiLinkedin,
  },
  {
    key: 'facebook',
    title: 'Facebook',
    href: 'https://www.facebook.com/',
    icon: SiFacebook,
  },
];

export default socialMediaLinks;
