import styles from '@/components/dropdown/dropdown.module.scss';
import {Children, cloneElement, Component, createRef, isValidElement} from 'react';
import type {ReactElement, RefObject} from 'react';

interface DropDownContextProps {
  readonly isOpen: boolean;
  readonly toggle: () => void;
}

interface DropDownChildProps {
  readonly dropdown: DropDownContextProps;
}

type DropDownPropsChild = ReactElement<DropDownChildProps>;

interface DropDownProps {
  readonly children: DropDownPropsChild | DropDownPropsChild[];
}

interface DropDownState {
  readonly isOpen: boolean;
}

class DropDown extends Component<DropDownProps, DropDownState> {
  private readonly wrapperRef: RefObject<HTMLDivElement>;
  public readonly state: DropDownState = {isOpen: false};

  constructor(props: DropDownProps) {
    super(props);
    this.wrapperRef = createRef<HTMLDivElement>();
  }

  public componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public render(): JSX.Element {
    return (
      <div className={styles.dropdown_container} ref={this.wrapperRef}>
        {Children.map(this.props.children, this.injectDropdown)}
      </div>
    );
  }

  /** @internal */
  private injectDropdown = (child: DropDownPropsChild) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        dropdown: {
          isOpen: this.state.isOpen,
          toggle: this.toggle,
        },
      });
    }
    return child;
  };

  /**
   * @internal
   * @description Close if clicked on outside of the menu element.
   * @param {MouseEvent} event - mouse click event.
   */
  private handleClickOutside = (event: MouseEvent): void => {
    if (this.state.isOpen && this.wrapperRef && !this.wrapperRef.current?.contains(event.target as Node)) {
      this.setState({isOpen: false});
    }
  };

  /** @internal */
  private toggle = () => {
    this.setState({isOpen: !this.state.isOpen});
  };
}

export default DropDown;
