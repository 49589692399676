import type {LayoutProps} from '@/components/layouts/empty-layout';
import EmptyLayout from '@/components/layouts/empty-layout';
import MainNavbar from '@/components/navbar/main-navbar';
import DashboardNavbar from '@/components/navbar/dashboard-navbar';
import MainFooter from '@/components/common/main-footer';
import Link from 'next/link';
import type {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {useAuth} from '@/contexts/auth-context';
import {ServiceProvider, ServiceProviderProfile} from '@soubul/ts-commons';
import {useEffect, useState} from 'react';
import axios from '@/utils/axios';

function ProfileNotCompletedAlert() {
  const auth = useAuth();
  const [profile, setProfile] = useState(ServiceProviderProfile.fromJSON({}));

  useEffect(() => {
    axios
      .get(`/service-providers/${auth.user?.username}`)
      .then(({data}) => setProfile(ServiceProviderProfile.fromJSON(data)))
      .catch();
  }, []);

  if (profile.id && profile.profileCompleteness < 100) {
    return (
      <div className="bg-yellow-300 text-center py-2">
        <FormattedMessage id="words.please_complete_your" />
        &nbsp;
        <Link href="/provider/settings" passHref>
          <a className="text-blue-500 font-semibold">
            <FormattedMessage id="words.profile" />
          </a>
        </Link>
        &nbsp;
        <FormattedMessage id="words.to_start_services_on_Soubul25" />
        {profile.profileCompleteness}
      </div>
    );
  }
  return null;
}

const MainLayout: FC<LayoutProps> = ({children, metadata}) => {
  const auth = useAuth();
  return (
    <EmptyLayout metadata={metadata}>
      {auth.user && ServiceProvider.isServiceProvider(auth.user as unknown as ServiceProvider) && (
        <ProfileNotCompletedAlert />
      )}
      {auth.user ? <DashboardNavbar /> : <MainNavbar />}
      <main className="flex flex-col flex-1">{children}</main>
      <MainFooter />
    </EmptyLayout>
  );
};

export default MainLayout;
