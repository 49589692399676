import type PageMetadata from '@/models/page-metadata';

const DEFAULT_PAGE_METADATA: PageMetadata = Object.freeze({
  title: 'Soubul',
  description: `An online platform that facilitates the connection of individuals with qualified teachers, specialists, and trainers to obtain educational, qualification, or training services in various fields through home visits,  online sessions, or at center.`,
  keywords: `teachers,trainers,education`,
  image: 'https://soubul.com/assets/soubul-logo.png',
});

export default DEFAULT_PAGE_METADATA;
