import DropDown from '@/components/dropdown/dropdown';
import DropDownToggle from '@/components/dropdown/dropdown-toggle';
import DropDownMenu from '@/components/dropdown/dropdown-menu';
import DropDownItem from '@/components/dropdown/dropdown-item';

import {withAuth} from '@/contexts/auth-context';
import {FiBell, FiBellOff} from 'react-icons/fi';
import {Component} from 'react';
import {withAlerts} from '@/contexts/alerts-context';
import {withRouter} from 'next/router';
import {WithRouterProps} from 'next/dist/client/with-router';

import {FormattedMessage} from 'react-intl';
import type {WithAuthProps} from '@/contexts/auth-context';
import type {WithAlertsProps} from '@/contexts/alerts-context';

type Props = WithAuthProps & WithAlertsProps & WithRouterProps;

class NotificationsDropDownMenu extends Component<Props> {
  public render(): JSX.Element {
    return (
      <DropDown>
        <DropDownToggle className="w-full" chevron={false}>
          <div>
            <FiBell size={22} />
          </div>
        </DropDownToggle>
        <DropDownMenu>
          <DropDownItem role="button">
            <div className="flex flex-col items-center text-gray-600">
              <span className="block mb-1">
                <FiBellOff size={26} />
              </span>
              <span className="block"><FormattedMessage id="words.no_new_notification"/></span>
            </div>
          </DropDownItem>
        </DropDownMenu>
      </DropDown>
    );
  }
}

export default withRouter(withAlerts(withAuth(NotificationsDropDownMenu)));
