import Link from 'next/link';
import styles from '@/components/navbar/main-navbar.module.scss';
import UserDropDownMenu from '@/components/common/user-drop-down-menu';
import NotificationsDropDownMenu from '@/components/common/notifications-drop-down-menu';
import MessageMenu from '@/components/common/message-menu';
import SearchInput from './search-input';
import cx from 'classnames';

import {withRouter} from 'next/router';
import {Component} from 'react';
import {FormattedMessage} from 'react-intl';

import type {WithRouterProps} from 'next/dist/client/with-router';

interface State {
  readonly menuOpen: boolean;
  readonly hasShadow: boolean;
}

class DashboardNavbar extends Component<WithRouterProps, State> {
  public readonly state: State;

  constructor(props: WithRouterProps) {
    super(props);
    this.state = {
      menuOpen: false,
      hasShadow: this.windowScrollY() > 50,
    };
  }

  public componentDidMount(): void {
    window.addEventListener('scroll', this.scrollHandler);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('scroll', this.scrollHandler);
  }

  public render(): JSX.Element {
    const {hasShadow, menuOpen} = this.state;
    return (
      <nav className={cx(styles.main_navbar, {[styles.raised]: hasShadow})}>
        <div className={styles.navbar_container}>
          <Link href="/" passHref>
            <a className="flex items-center">
              <img src="assets/images/soubul-192-white.png" alt="Soubul" className="block w-16" />
              <div className="mx-3 flex flex-col">
                <span className="text-lg font-light tracking-widest">
                  <FormattedMessage id="words.soubul" />
                </span>
                <span className="text-xs -mt-1 tracking-widest">
                  <FormattedMessage id="words.academy" />
                </span>
              </div>
            </a>
          </Link>

          <div className="block md:hidden">
            <button className={styles.menu_toggle} onClick={this.toggleMobileMenu}>
              <svg className="h-3 w-3 fill-current" viewBox="0 0 20 20">
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>

          <div className={cx(styles.navbar_menu, menuOpen ? 'block' : 'hidden')}>
            <div className="flex flex-1 lg:px-8 my-4 md:my-0">
              <SearchInput />
            </div>
            <div className="mx-4 my-4 md:my-0">
              <MessageMenu />
            </div>
            <div className="mx-4 my-4 md:my-0">
              <NotificationsDropDownMenu />
            </div>
            <div className="my-4 md:my-0">
              <UserDropDownMenu />
            </div>
          </div>
        </div>
      </nav>
    );
  }

  /** @internal */
  private windowScrollY = () => {
    return typeof window !== 'undefined' ? window.scrollY : 0;
  };

  /** @internal */
  private scrollHandler = () => {
    this.setState({hasShadow: this.windowScrollY() > 50});
  };

  /** @internal */
  private toggleMobileMenu = () => {
    this.setState({menuOpen: !this.state.menuOpen});
  };
}

export default withRouter(DashboardNavbar);
