import DropDownToggle from '@/components/dropdown/dropdown-toggle';
import DropDownMenu from '@/components/dropdown/dropdown-menu';
import DropDownItem from '@/components/dropdown/dropdown-item';
import DropDown from '@/components/dropdown/dropdown';
import NextLink from '@/components/next-link';
import {FormattedMessage} from 'react-intl';
import {useRouter} from 'next/router';
import {IoLanguageOutline} from 'react-icons/io5';
import type {FC} from 'react';

const LanguageMenu: FC = () => {
  const router = useRouter();
  return (
    <DropDown>
      <DropDownToggle>
        <IoLanguageOutline className="mx-2" />
        <FormattedMessage id="words.language" />
      </DropDownToggle>
      <DropDownMenu placement="bottom">
        <NextLink href={router.asPath} locale="en" passHref>
          <DropDownItem target="_parent">English</DropDownItem>
        </NextLink>
        <NextLink href={router.asPath} locale="ar" passHref>
          <DropDownItem target="_parent">العربية</DropDownItem>
        </NextLink>
      </DropDownMenu>
    </DropDown>
  );
};

export default LanguageMenu;
