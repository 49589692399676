import Head from 'next/head';
import styles from '@/components/layouts/empty-layout.module.scss';
import DEFAULT_PAGE_METADATA from '@/data/page-metadata';

import type PageMetadata from '@/models/page-metadata';
import type {FC, ReactNode} from 'react';

export interface LayoutProps {
  readonly children: ReactNode;
  readonly metadata: PageMetadata;
}

const EmptyLayout: FC<LayoutProps> = ({children, metadata}) => {
  const meta = {...DEFAULT_PAGE_METADATA, ...metadata};

  return (
    <div className={styles.empty_layout}>
      <Head>
        <base href="/" />
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />

        <title>{meta.title}</title>

        <meta name="theme-color" content="#376c22" />
        <meta name="description" content={meta.description} />
        <meta name="keywords" content={meta.keywords} />

        {/* Twitter Card Config */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="https://soubul.com" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:creator" content="@akaahmedkamal" />
        <meta name="twitter:image" content={meta.image} />

        {/* Open Graph Config */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:site_name" content="Soubul" />
        <meta property="og:url" content="https://soubul.com" />
        <meta property="og:image" content={meta.image} />

        <link rel="manifest" href="assets/manifest.json" />
        <link rel="icon" type="image/x-icon" href="favicon.ico" />
        <link rel="apple-touch-icon" href="assets/images/soubul-192.png" />
      </Head>
      {children}
    </div>
  );
};

export default EmptyLayout;
