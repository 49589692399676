import {FiSearch, FiX} from 'react-icons/fi';
import {useState} from 'react';
import {useRouter} from 'next/router';
import type {FC, ChangeEvent} from 'react';
import {useIntl} from 'react-intl';

// An alias for the change event of the HTML input.
type InputChangeEvent = ChangeEvent<HTMLInputElement>;

let typingTimeout: NodeJS.Timeout | undefined;

const useSearch = () => {
  const router = useRouter();
  const [value, setValue] = useState(router.query.q ?? '');

  return {
    // The value of the search input.
    value,

    // Input change handler that call the submit event when the user stop typing.
    onChange({target}: InputChangeEvent) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      typingTimeout = setTimeout(() => {
        router.push(`/search?q=${target.value}`);
      }, 1000);
      setValue(target.value);
    },

    // A helper method to clear the search input.
    clear() {
      setValue('');
    },
  };
};

const SearchInput: FC = () => {
  const intl = useIntl();
  const {value, onChange, clear} = useSearch();
  return (
    <div className="flex items-center bg-accent-400 bg-opacity-50 rounded-xl px-4 py-2 w-full md:w-auto">
      <FiSearch className="text-gray-400 ltr:mr-3 rtl:ml-3" />
      <input placeholder={intl.formatMessage({id:"words.search_categories_services_providers"})}
      className="bg-transparent"
      onChange={onChange}
      value={value} 
        // placeholder="Search categories, services, or service providers"
        // className="bg-transparent"
        // onChange={onChange}
        // value={value}
      />
      {value && (
        <button className="text-gray-400 ltr:ml-3 rtl:mr-3" onClick={clear}>
          <FiX />
        </button>
      )}
    </div>
  );
};

export default SearchInput;
